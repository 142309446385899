import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  { path: '/tables/companyDetails', title: 'Company', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/tables/employeeDetails', title: 'Users Details', icon: 'ft-mail', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/tables/projectDetails', title: 'Projects', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/forms/reports', title: 'Reports', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/tables/settings', title: 'Settings', icon: 'fa fa-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
];

export const ROUTES_ADMIN_LITE: RouteInfo[] = [
  { path: '/tables/projectDetails', title: 'Projects', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/forms/reports', title: 'Reports', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
];

export const ROUTES_CLIENT: RouteInfo[] = [
  { path: '/tables/projectDetails', title: 'Projects', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/forms/reports', title: 'Reports', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
];

export const ROUTES_EMPLOYEE: RouteInfo[] = [
  { path: '/tables/projectDetails', title: 'Projects', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
];
