<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
     Copyright &copy; {{currentDate | date: 'yyyy'}} {{titleName}} 
    <!--  <a id="Getway Solution"
      href="http://www.getwaysolution.com/">Getway Solution</a> -->
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p>
</footer>
<!--Footer Ends-->
