import { Component, HostBinding } from '@angular/core';
import { HttpCommonMethods } from 'app/httpCommonMethods/httpCommonMethods';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    //Variables
    titleName : string;
    currentDate : Date = new Date();
    constructor(private httpCommonMethods : HttpCommonMethods){
       this.getLogoDetails();
    }

    getLogoDetails(){
        this.httpCommonMethods.getLogoDetails()
          .subscribe(data => {
            if (data != null) {
              console.log('getLogoDetails')
              console.log(data)
              this.titleName = data[0]['title_name'];
            }
            else {
              alert('Invalid Project Selected');
            }
          });
    }
}
