import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable()
export class HttpCommonMethods  implements OnInit  {
  //public url = 'http://localhost/angular';
  //public url = 'http://ronm23.sg-host.com'; 
  //public url = 'https://marisinfra.app';
  public url = 'https://company.authotrace.nl'; 
  public tempURL = this.url + '/upload/';
  public sessionTimeOut = 120 * 60; 

  fullName : string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
 
  errorData: {  };
  loggedInUser : string;

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  constructor(private http: HttpClient) {
    this.fullName = localStorage.getItem('employee_name');
    
  }

  ngOnInit() {
    
  }

  public deleteUser(userPK) {
     const params = new HttpParams()
       .set('userPK', userPK.toString());
    return this.http.delete<any>(this.url + '/deleteUser.php', { params }).pipe(map(data => {
      return data['msg'];
    }),
    catchError(this.handleError)
    );
  }

  public deleteProject(projectPK) {
    const params = new HttpParams()
      .set('projectPK', projectPK.toString());
    return this.http.delete<any>(this.url + '/deleteProject.php', { params }).pipe(map(data => {
     return data['msg'];
   }),
   catchError(this.handleError)
   );
 }
 
 
 public deleteCommentByPK(commentPK) {
  const params = new HttpParams()
    .set('commentPK', commentPK.toString());
    console.log(params);
  return this.http.delete<any>(this.url + '/deleteCommentByPK.php', { params }).pipe(map(data => {
   return data['msg'];
 }),
 catchError(this.handleError)
 );
}

  public deleteCompany(companyPK) {
    const params = new HttpParams()
      .set('companyPK', companyPK.toString());
   return this.http.delete<any>(this.url + '/deleteCompany.php', { params }).pipe(map(data => {
     return data['msg'];
   }),
   catchError(this.handleError)
   );;
 }

  public updateUser(username) {
    return this.http.put<any>(this.url + '/updateUser.php', { username })
        .pipe(map(data => {
          return data['msg'];
        }),
        catchError(this.handleError)
        );
  }

  public userlogin(username, password) {
    return this.http.post<any>(this.url + '/login.php', { username, password })
        .pipe(map(Users => {
          
          if(Users.length > 0 && Users[0]['name'] != ''){
          this.loggedInUser = Users[0]['first_name'] + ' ' + Users[0]['last_name'];
          localStorage.setItem('username', Users[0]['name']);
          localStorage.setItem('loggedInUser', this.loggedInUser);
          localStorage.setItem('email', Users[0]['email']);
          localStorage.setItem('employee_name', Users[0]['employee_name']);
          localStorage.setItem('fk_tb_project', Users[0]['fk_tb_project']);
          localStorage.setItem('address', Users[0]['address']);
          localStorage.setItem('contact_number', Users[0]['contact_number']);
          localStorage.setItem('currentUser',Users[0]['name']);
          localStorage.setItem('role',Users[0]['role']);
          localStorage.setItem('fk_tb_roles',Users[0]['fk_tb_roles']);
          localStorage.setItem('password',Users[0]['password']);
          localStorage.setItem('fk_tb_company_details',Users[0]['fk_tb_company_details']);
          localStorage.setItem('company_name',Users[0]['company_name']);
          localStorage.setItem('pk_tb_user_details',Users[0]['pk_tb_user_details']);
          return Users;
          }
          else {
            return null;
          }
        })
        // ,
        // catchError(this.handleError)
        );
}

public getEmpUsers() {
  return this.http.post<any>(this.url + '/getEmpUsers.php', { })
      .pipe(map(Users => {
        if(Users.length > 0){
        localStorage.setItem('currentUser', Users);
        return Users;
        }
        else {
          return null;
        }
      })
      // ,
      // catchError(this.handleError)
      );
}


public getCombinedProjectDetails() {
  return this.http.post<any>(this.url + '/getCombinedProjectDetails.php', { })
      .pipe(map(projectData => {
        if(projectData.length > 0){
        return projectData;
        }
        else {
          return null;
        }
      })
      );
}

//getCombinedProjectDetailsByStatus

public getCombinedProjectDetailsByStatus() {
  return this.http.post<any>(this.url + '/getCombinedProjectDetailsByStatus.php', { })
      .pipe(map(projectData => {
        if(projectData.length > 0){
        return projectData;
        }
        else {
          return null;
        }
      })
      );
}

public getProjectDetailsForAdminLiteByPK(fk_tb_company_details) {
 
  return this.http.post<any>(this.url + '/getProjectDetailsForAdminLiteByPK.php', { fk_tb_company_details })
      .pipe(map(data => {
        
        if(data.length > 0){
        return data;
        }
        else {
          return null;
        }
      })
      );

}


public getCombinedProjectDetailsByPK(fk_tb_company_details) {
 
    return this.http.post<any>(this.url + '/getCombinedProjectDetailsByPK.php', { fk_tb_company_details })
        .pipe(map(data => {
          
          if(data.length > 0){
          return data;
          }
          else {
            return null;
          }
        })
        );

}
//getCompanyDetailsByPK
public getCompanyDetailsByPK(pk_tb_company_details) {
  return this.http.post<any>(this.url + '/getCompanyDetailsByPK.php', { pk_tb_company_details})
      .pipe(map(Company => {
        if(Company.length > 0){
        localStorage.setItem('currentUser', Company);
        return Company;
        }
        else {
          return null;
        }
      })
      // ,
      // catchError(this.handleError)
      );
}

public getCompanyDetails() {
  return this.http.post<any>(this.url + '/getCompanyDetails.php', { })
      .pipe(map(Company => {
        if(Company.length > 0){
        localStorage.setItem('currentUser', Company);
        return Company;
        }
        else {
          return null;
        }
      })
      // ,
      // catchError(this.handleError)
      );
}

public getCompanyDetailsForClient(fk_tb_user_details) {
  return this.http.post<any>(this.url + '/getCompanyDetailsForClient.php', { fk_tb_user_details })
        .pipe(map(data => {
          if(data.length > 0 ){
            return data;
          }
          else {
            return null;
          }
        })    
        );
}

public getSpecificUserDetails(userPK) {
  return this.http.post<any>(this.url + '/getSpecificUserDetails.php', { userPK })
      .pipe(map(Users => {
        if(Users.length > 0){
        return Users;
        }
        else {
          return null;
        }
      })
      // ,
      // catchError(this.handleError)
      );
}

public getUserRoles() {
    
  return this.http.post<any>(this.url + '/getUserRoles.php', { })
      .pipe(map(Users => {
        if(Users.length > 0){
        
        return Users;
        }
        else {
          return null;
        }
      })
      // ,
      // catchError(this.handleError)
      );
}


public getProjects() {
    return this.http.post<any>(this.url + '/getProjects.php', { })
      .pipe(map(Users => {
        if(Users.length > 0){
        return Users;
        }
        else {
          return null;
        }
      })
      // ,
      // catchError(this.handleError)
      );
}


public getLogoDetails() {
  return this.http.post<any>(this.url + '/getLogoDetails.php', { })
    .pipe(map(Users => {
      if(Users.length > 0){
        console.log(Users);
      return Users;
      }
      else {
        return null;
      }
    })
    // ,
    // catchError(this.handleError)
    );
}


public getProjectsByCompanyPK(fk_tb_company_details) {
  return this.http.post<any>(this.url + '/getProjectsByCompanyPK.php', { fk_tb_company_details })
    .pipe(map(Users => {
      if(Users.length > 0){
      return Users;
      }
      else {
        return null;
      }
    })
    // ,
    // catchError(this.handleError)
    );
}

public getProjectsByClientCompanyPK(fk_tb_company_details) {
  return this.http.post<any>(this.url + '/getProjectsByClientCompanyPK.php', { fk_tb_company_details })
    .pipe(map(Users => {
      if(Users.length > 0){
      return Users;
      }
      else {
        return null;
      }
    })
    // ,
    // catchError(this.handleError)
    );
}

//updateUserBasedOnPK
public updateUserBasedOnPK(userPK, employeeName, username, password, 
  fk_tb_company_details, company_name, fk_tb_roles,  userRole, address, contact_number, email ) {
    //userPK, this.employeeName, this.username, 
    //this.password, this.fk_tb_roles, this.selectedRole, this.selectedProjectIds, 
    //this.address, this.contact_number, this.email
  return this.http.put<any>(this.url + '/updateUserBasedOnPK.php', {userPK, employeeName,
     username, password,  fk_tb_company_details, company_name, fk_tb_roles, userRole, address, contact_number, email })
      .pipe(map(data => {
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}

public saveEditedComment(pk_tb_comment, comment, file_names, created_on) {
    
  return this.http.put<any>(this.url + '/saveEditedComment.php', { pk_tb_comment, comment, file_names, created_on })
      .pipe(map(data => {
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}

public saveDeletedImage(pk_tb_comment, file_names) {
  console.log('save deleted Image')
  return this.http.put<any>(this.url + '/saveDeletedImage.php', { pk_tb_comment, file_names })
      .pipe(map(data => {
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}

public updateProjectBasedOnPK(projectPK, projectNumber, projectName, projectDate, 
  selectedProjectStatus, address, instructionForEmployee, selectedCompanyPK,  selectedCompanyName, editable_by_adminlite ) {
  return this.http.put<any>(this.url + '/updateProjectBasedOnPK.php', {projectPK, projectNumber, projectName, projectDate, 
    selectedProjectStatus, address, instructionForEmployee, selectedCompanyPK,  selectedCompanyName, editable_by_adminlite})
      .pipe(map(data => {
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}

  public updateAdminFileNamesBasedOnPK(project_pk, file_names ) {
  return this.http.put<any>(this.url + '/updateAdminFileNamesBasedOnPK.php', {project_pk, file_names})
      .pipe(map(data => {
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}

public updateCompanyBasedOnPK(pk_tb_company_details, clientName, address, contact_number, email ) {
  return this.http.put<any>(this.url + '/updateCompanyBasedOnPK.php', { pk_tb_company_details, clientName, address, contact_number, email })
      .pipe(map(data => {
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}

public insertUserDetails(employeeName, username, password, pk_tb_roles, userRole, 
  first_name, last_name, address, contact_number, email,
  fk_tb_company_details,  company_name
  ) {
  
  return this.http.post<any>(this.url + '/insertUserDetails.php', { employeeName, username, password,
     pk_tb_roles, userRole, first_name, last_name, address, contact_number, email,
     fk_tb_company_details,  company_name
    })
      .pipe(map(data => {
        return data;
      }),  
      catchError(this.handleError)
      );
}

public updateLogoDetails(titleName, titleAddress, titlePhoneNumber, titleEmail ) {
  return this.http.put<any>(this.url + '/updateLogoDetails.php', { titleName, titleAddress, titlePhoneNumber, titleEmail })
      .pipe(map(data => {
        console.log(data)
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}


public insertCompanyDetails(clientName, address, contact_number, email) {
  return this.http.post<any>(this.url + '/insertCompanyDetails.php', { clientName, address, contact_number, email })
      .pipe(map(data => {
        return data;
      }),  
      catchError(this.handleError)
      );
}

public insertCommentDetails(comment, fk_tb_project, fk_tb_user_details, file_names, username) {
  return this.http.post<any>(this.url + '/insertCommentDetails.php', { comment, fk_tb_project, fk_tb_user_details, file_names, username })
      .pipe(map(data => {
        return data;
      }),  
      catchError(this.handleError)
      );
}





public insertProjectDetails(status, projectDeliveryDate, 
  projectNumber, projectName, selectedCompanyPK, selectedCompany, address, instructionForEmployee, editable_by_adminlite) {
    return this.http.post<any>(this.url + '/insertProjectDetails.php', { status, projectDeliveryDate, 
    projectNumber, projectName, selectedCompanyPK, selectedCompany, address, instructionForEmployee, editable_by_adminlite })
      .pipe(map(data => {
        return data;
      })
      );
}


public uploadFiles(file_data, pk_tb_project) {
  return this.http.post<any>(this.url + '/upload.php',file_data, pk_tb_project)
  .pipe(map(data => {
    return data;
  })
  );
}

public uploadLoginLogo(file_data) {
  return this.http.post<any>(this.url + '/uploadLoginLogo.php',file_data)
  .pipe(map(data => {
    return data;
  })
  );
}

public updateLoginLogoFileName( fileName, column_name) {
  return this.http.put<any>(this.url + '/updateLoginLogoFileName.php', { fileName, column_name })
      .pipe(map(data => {
        console.log(data)
        return data['msg'];
      }),
      catchError(this.handleError)
      );
}

public uploadDashboardLogo(file_data) {
  return this.http.post<any>(this.url + '/uploadDashboardLogo.php',file_data)
  .pipe(map(data => {
    return data;
  })
  );
}

public uploadPrintLogo(file_data) {
  return this.http.post<any>(this.url + '/uploadPrintLogo.php',file_data)
  .pipe(map(data => {
    return data;
  })
  );
}

public uploadFavIconLogo(file_data) {
  return this.http.post<any>(this.url + '/uploadFavIconLogo.php',file_data)
  .pipe(map(data => {
    return data;
  })
  );
}



//uploadFilesByUser
public uploadFilesByUser(file_data, pk_tb_project) {
  return this.http.post<any>(this.url + '/upload_for_user.php',file_data, pk_tb_project)
  .pipe(map(data => {
    return data;
  })
  );
}

public fetchCommentsToLoad(fk_tb_project) {
  return this.http.post<any>(this.url + '/fetchCommentsToLoad.php', { fk_tb_project })
      .pipe(map(data => {
        return data;
      })
      );
}

public fetchUploadedFiles(fk_tb_project) {
  return this.http.post<any>(this.url + '/fetchUploadedFiles.php', { fk_tb_project })
      .pipe(map(data => {
        return data;
      })
      );
}

public checkUsernameAndEmail(username, email, pk_tb_user_details) {
  return this.http.post<any>(this.url + '/checkUsernameAndEmail.php', { username, email, pk_tb_user_details })
      .pipe(map(data => {
        if(data.length > 0){
        return data;
        }
        else {
          return null;
        }
      })
      // ,
      // catchError(this.handleError)
      );
}

public getFullName(pk_tb_user_details) {
  return this.http.post<any>(this.url + '/getFullName.php', { pk_tb_user_details })
      .pipe(map(data => {
        return data;
      })
      );
}

  
  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

  public getReportsData(fk_tb_project, project_name, fromDate, toDate) {
    return this.http.post<any>(this.url + '/getReportsData.php', { fk_tb_project, project_name , fromDate, toDate})
        .pipe(map(data => {
          if(data.length > 0){
            return data;
          }
          else {
            return null;
          }
        })
        );
}

public getProjectDataBasedOnFilter(status, fromDate, toDate) {
  
    return this.http.post<any>(this.url + '/getProjectDataBasedOnFilter.php', { status , fromDate, toDate})
      .pipe(map(data => {
        if(data.length > 0){
          return data;
        }
        else {
          return null;
        }
      })
      );
  
  
}

  //getLoggedInEmpDetails
  public getLoggedInEmpDetails(pk_tb_user_details) {
    return this.http.post<any>(this.url + '/getLoggedInEmpDetails.php', { pk_tb_user_details })
        .pipe(map(data => {
          if(data.length > 0){
            return data;
          }
          else {
            return null;
          }
        })
        // ,
        // catchError(this.handleError)
        );
}

  
}
